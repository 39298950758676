import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Components/Home/home'
import ForgotPassword from './Components/Login/ForgotPassword/forgot_password'
import ResetPassword from './Components/Reset password/reset_password'
import LoginPage from './Components/Login/Login'
import ReportList from './Components/Home/Myreport/ReportList'
import OriginalVideos from './Components/Home/OriginalVideos/OriginalVideos'
import ExportedVideo from './Components/Home/ExportedVideo/ExportedVideo'
import {ServiceInfo, UserContext} from "./Utilis"
import {useState } from 'react'
import Biofouling from './Components/Home/Apps/Biofouling/Biofouling';
import TableApp from './Components/Home/Apps/TableApp/TableApp';
import FishCounting from './Components/Home/Apps/FishCounting/FishCounting';
import SalmorApp from './Components/Home/Apps/SalmorApp/SalmorApp';
import BiomassApp from './Components/Home/Apps/BiomassApp/BiomassApp';
import Mortality from './Components/Home/Apps/Mortality/Mortality';
import Waste from './Components/Home/Apps/Waste/Waste';
import Inspection from './Components/Home/Apps/Inspection/Inspection';
import Custom from './Components/Home/Apps/Custom/Custom';
import Cagedetection from './Components/Home/Apps/Cagedetection/Cagedetection';
import AppDisplay from './Components/Home/AppDisplay'
import SveVrste from './Components/Vrste/Svevrste'
import NotFound from './Components/404/Error'
import BiofoulingCageReport from './Components/Home/Apps/Biofouling/BiofoulingCageReport/BiofoulingCageReport'
import FishType from './Components/Vrste/FishType/FishType'

function App() {
  const [user,setUser]=useState(null)

 
  return (
    <UserContext.Provider value={{user:user,setUser:setUser}}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginPage />}></Route>
          <Route path='/*' element={<NotFound />}></Route>
          <Route path = "/forgotPassword" element={<ForgotPassword />}></Route>
          <Route path = "/resetPassword/:token" element={<ResetPassword />}></Route>
          <Route path="/ribe" element={<SveVrste/>} /> 
            <Route path = "ribe/:vrsta" element={<FishType />}></Route>
          <Route path="/home" element={<Home/>} >
            <Route path="/home/apps" element={<AppDisplay />} />
            <Route path="/home/tableapp" element={<TableApp name={ServiceInfo.TableApp.Name} service="TableApp" />} />
            <Route path="/home/fishcounting" element={<FishCounting name={ServiceInfo.FishCounting.Name} service="FishCounting" />} />
            <Route path="/home/salmorapp" element={<SalmorApp name={ServiceInfo.SalmorApp.Name} service="SalmorApp" />} />
            <Route path="/home/biomassapp" element={<BiomassApp name={ServiceInfo.BiomassApp.Name} service="BiomassApp" />} />
            <Route path="/home/mortality" element={<Mortality name={ServiceInfo.Mortality.Name} service="Mortality" />} />
            <Route path="/home/waste" element={<Waste name={ServiceInfo.Waste.Name} service="Waste" />} />
            <Route path="/home/inspection" element={<Inspection name={ServiceInfo.Inspection.Name} service="Inspection" />} />
            <Route path="/home/custom" element={<Custom name={ServiceInfo.Custom.Name} service="Custom" />} />
            <Route path="/home/biofouling" element={<Biofouling name={ServiceInfo.Biofouling.Name} />} /> 
            <Route path="/home/cagedetection" element={<Cagedetection name={ServiceInfo.Cagedetection.Name} service="Cagedetection" />} />
            <Route path="/home/MyReport/ReportList" Component={ReportList}/>
            <Route path='/home/OriginalVideos/OriginalVideos' Component={OriginalVideos}/>
            <Route path='/home/ExportedVideo/ExportedVideo' Component={ExportedVideo}/>
            <Route path='/home/biomassCageReport/:cageId' element={<BiofoulingCageReport/>} />
            <Route path='/home/biofoulingCageReport/:cageId' element={<BiofoulingCageReport/>} />

          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  )
}

export default App;

import { useNavigate } from "react-router-dom";
import { ServiceInfo, UserContext } from "../../Utilis"
import AppSquare from './Apps/AppSquare';
import { useContext } from "react";

function AppDisplay() {
  const { user } = useContext(UserContext);
  const navigator = useNavigate();

  const handleAppClick = (appName) => {
      const serviceName = ServiceInfo[appName].sqlName;
      if (!user[serviceName]) {
          return;
      }
      navigator(ServiceInfo[appName].link_url);
  };

  return (
      <>
          <div className="app-squares">
              {Object.entries(ServiceInfo).map(([name,service], index) => (
                  <AppSquare key={index} appName={name} onClick={() => handleAppClick(name)} isAccessible={!!user[ServiceInfo[name].sqlName]} />
              ))}
          </div>
      </>
  );
}

export default AppDisplay;
